
import styled from 'styled-components';
import React, { useState } from 'react';
import eye from '../assets/icons/eye.svg';

const InputWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    width: ${({ width }) => width || "274px"};
    height: 40px;
    border-radius: 4px;
    border: 1px solid #8A99A6;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px #EEEFF4 inset;
    box-sizing: border-box;

    margin: ${({ margin }) => margin || '0px'};
    padding: 10px 10px 10px 15px;
`;
export const Input = styled.input`
    border: none;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
`;

const EyeButton = styled.button`
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    width: 27px;
    height: 10px;
    background: url("${eye}");
    background-size: 30px 10px;
    &:focus {
        outline: none;
    }
`;

const PasswordInput = ({ onChange, placeholder, value }) => {
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = (e)=> {
        e.preventDefault();
        if (passwordType==="password")
        {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    return(
        <InputWrapper>
            <Input type={passwordType} placeholder={placeholder} value={value} onChange={(e) => onChange(e)}>
            </Input>
            <EyeButton type="button" onClick={(e) => togglePassword(e)}></EyeButton>
        </InputWrapper>
    )
}

export default PasswordInput;
