import styled from 'styled-components';
import React from 'react';
import Link from '../components/Link';
import warning2 from '../assets/icons/warning2.svg';
import {useTranslation} from "react-i18next";
import settings from "../settings.json";

const WarningBannerWrapper = styled.div`
    position: relative;
    background: #FFF6D9;
    width: 530px;
    height: auto;
    padding: 12px 12px 12px 60px;
    margin: 36px 0;
    margin-top: 0;
    border-radius: 4px;
    
    &:before {
        content: ' ';
        position: absolute;
        top: 14px;
        left: 27px;
        background: url("${warning2}");
        background-repeat: no-repeat;
        width: 18px;
        height: 17px;
        display: block;
    }

    @media only screen and (max-width: 420px) {
        width: 340px;
    }
`;

const WarningBannerTitle = styled.h2`
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    max-width: 368px;

    @media only screen and (max-width: 420px) {
        max-width: 220px;
    }
`;

const WarningBannerDescription = styled.p`
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    @media only screen and (max-width: 420px) {
        max-width: 476px;
    }
`;

const WarningBanner = ({ language }) => {
    const { t, i18n } = useTranslation();
    const htmlDecode = (input) => {
        let e = document.createElement('div');
        e.innerHTML = input;
        return e.innerHTML;
    }
    return(
        <WarningBannerWrapper>
            <WarningBannerTitle>
                {t('WarningTitle')}
            </WarningBannerTitle>
            <WarningBannerDescription dangerouslySetInnerHTML={{ __html: htmlDecode(t('WarningDescription'))}}>
            </WarningBannerDescription>
            <Link href={language === 'en' ? settings.betaInstructionEn : settings.betaInstructionRu}>
                {t('WarningDetails')}
            </Link>
        </WarningBannerWrapper>
    )
}

export default WarningBanner;
